import type {SsaiAdBreakMetadata, SsaiAdMetadata, SsaiAdQuartile, SsaiAdQuartileMetadata} from '../../api/AdapterAPI';
import {AD_TYPE} from '../../enums/AdType';
import {ErrorCode} from '../../enums/ErrorCode';
import {customDataValuesKeys, extractCustomDataFieldsOnly} from '../../types/CustomDataValues';
import type {AnalyticsEventBase} from '../../types/EventData';
import type {Sample} from '../../types/Sample';
import {generateUUID} from '../../utils/Utils';

import type {InternalSsaiMetadata, SsaiEngagementInteractionData} from './ssaiEngagementInteractionData';
import {
  getCurrentMonotonicTimestampInMs,
  getMonotonicTimestampInMsSince,
  SSAI_RELATED_SAMPLE_MARKER,
} from './ssaiUtils';

export type SsaiEventHandler = {
  getPlayerCurrentTime: () => number;
  reportEngagementInteraction: (data: SsaiEngagementInteractionData) => void;
  reportPlaybackInteraction: (timestamp: number, eventData: AnalyticsEventBase) => void;
};

enum SsaiState {
  AD_BREAK_STARTED,
  ACTIVE,
  IDLE,
}

export class SsaiService {
  /** if `undefined` then service is not active and should not do anything, why: initially we activate it only for Bitmovin8 */
  private eventHandler: SsaiEventHandler | undefined = undefined;

  /** Index of the ads in the whole session */
  private adIndex: number = 0;

  private state: SsaiState = SsaiState.IDLE;
  private isFirstSampleOfAd = false;
  /**
   * represents the current playing ad metadata
   * is `undefined` when no ad is playing (after endAdBreak)
   * or omitted by customer
   */
  private currentAdMetadata: InternalSsaiMetadata | undefined;

  /**
   * The impression id for the currently playing ad
   * is undefined when no ad is playing
   */
  private currentAdImpressionId: string | undefined;

  /** List of quartiles already sent for the current ad */
  private sentAdQuartiles: SsaiAdQuartile[] = [];

  /**
   * Flags whether an error was sent during an ad to avoid sending multiple errors for one ad,
   * which would skew e.g. the ad abandonment rate.
   */
  private errorSent: boolean = false;

  /**
   * The timestamp marking when the `adStart` method was called.
   * This timestamp is used to calculate the time difference between the `adStart` call
   * and subsequent `adQuartileFinished` or `sendAdEngagementErrorSample` calls.
   */
  private timestampOfAdStart: number | undefined;

  activate(eventHandler: SsaiEventHandler) {
    this.eventHandler = eventHandler;
  }

  adBreakStart(adBreakMetadata?: SsaiAdBreakMetadata) {
    if (!isActivated(this.eventHandler)) {
      // nothing to do if service is not active
      return;
    }

    if (this.state != SsaiState.IDLE) {
      return;
    }

    this.state = SsaiState.AD_BREAK_STARTED;
    this.currentAdMetadata = {
      ...adBreakMetadata,
    };
  }

  adStart(adMetadata?: SsaiAdMetadata) {
    if (!isActivated(this.eventHandler)) {
      // nothing to do if service is not active
      return;
    }

    if (this.state == SsaiState.IDLE) {
      return;
    }

    const timestamp = new Date().getTime();
    const playerCurrentTime = this.eventHandler.getPlayerCurrentTime();

    // report playback sample - will be saved in our playback related data lake
    // - with the first adStart call we need to close the current normal playback sample (no ssai ad information yet because SsaiState is not yet set to active)
    // - with all following adStart calls, we will deliver previous ssai ad information (SsaiState is already set to active)
    this.eventHandler.reportPlaybackInteraction(timestamp, {currentTime: playerCurrentTime});

    this.state = SsaiState.ACTIVE;
    // to include the adIndex in the next sample which will be the first sample of this started ad
    this.isFirstSampleOfAd = true;

    this.resetAdData();

    // we are extracting the customData fields explicitly to make sure that
    // there is not a different object passed
    const customData = adMetadata?.customData != null ? extractCustomDataFieldsOnly(adMetadata.customData) : undefined;

    this.currentAdMetadata = {
      adPosition: this.currentAdMetadata?.adPosition,
      adId: adMetadata?.adId,
      adSystem: adMetadata?.adSystem,
      customData: customData,
    };

    this.currentAdImpressionId = generateUUID();
    this.timestampOfAdStart = getCurrentMonotonicTimestampInMs();

    // report engagement sample - will be saved in our ad related data lake
    this.eventHandler.reportEngagementInteraction({
      type: 'started',
      adIndex: this.adIndex,
      currentAdImpressionId: this.currentAdImpressionId,
      currentAdMetadata: this.currentAdMetadata != null ? this.currentAdMetadata : {},
    });
  }

  adQuartileFinished(adQuartile: SsaiAdQuartile, adQuartileMetadata: SsaiAdQuartileMetadata) {
    if (!isActivated(this.eventHandler)) {
      // nothing to do if service is not activated
      return;
    }

    if (this.state != SsaiState.ACTIVE) {
      return;
    }

    if (this.sentAdQuartiles.includes(adQuartile)) {
      //do nothing if adQuartile was already sent
      return;
    }

    this.eventHandler.reportEngagementInteraction({
      type: 'quartile',
      adIndex: this.adIndex,
      currentAdImpressionId: this.currentAdImpressionId!,
      currentAdMetadata: this.currentAdMetadata != null ? this.currentAdMetadata : {},
      quartile: adQuartile,
      quartileMetadata: adQuartileMetadata,
      timeSinceAdStartedInMs: getMonotonicTimestampInMsSince(this.timestampOfAdStart),
    });
    this.sentAdQuartiles.push(adQuartile);
  }

  adBreakEnd() {
    if (!isActivated(this.eventHandler)) {
      // nothing to do if service is not active
      return;
    }

    if (this.state == SsaiState.IDLE) {
      return;
    }

    if (this.state == SsaiState.ACTIVE) {
      const timestamp = new Date().getTime();
      const playerCurrentTime = this.eventHandler.getPlayerCurrentTime();
      this.eventHandler.reportPlaybackInteraction(timestamp, {currentTime: playerCurrentTime});
    }

    this.resetAdBreakData();
  }

  manipulate(sample: Sample): void {
    if (!isActivated(this.eventHandler)) {
      // nothing to do if service is not active
      return;
    }

    if (this.state != SsaiState.ACTIVE) {
      return;
    }

    sample.ad = AD_TYPE.SSAI;
    sample.adId = this.currentAdMetadata?.adId;
    sample.adSystem = this.currentAdMetadata?.adSystem;
    sample.adPosition = this.currentAdMetadata?.adPosition;
    if (this.isFirstSampleOfAd) {
      sample.adIndex = this.adIndex;
      this.isFirstSampleOfAd = false;
      this.adIndex++;
    }

    const customData = this.currentAdMetadata?.customData;
    if (customData != null) {
      customDataValuesKeys.forEach((key) => {
        if (customData[key]) {
          sample[key] = customData[key];
        }
      });
    }

    sample[SSAI_RELATED_SAMPLE_MARKER] = true; // mark it as ssai sample
  }

  sendAdEngagementErrorSample(errorCode: number, errorMessage: string) {
    if (!isActivated(this.eventHandler)) {
      // nothing to do if service is not active
      return;
    }

    if (this.state !== SsaiState.ACTIVE) {
      return;
    }

    // only send one error sample per ad
    if (this.errorSent) {
      return;
    }

    // This error code is related to the Analytics product and is non-fatal.
    // It is not triggered by the Player and does not interrupt or affect playback,
    // so there is no need to report an ad error sample.
    if (errorCode === ErrorCode.QUALITY_CHANGE_THRESHOLD_EXCEEDED.code) {
      return;
    }

    this.eventHandler.reportEngagementInteraction({
      type: 'error',
      adIndex: this.adIndex,
      currentAdImpressionId: this.currentAdImpressionId!,
      currentAdMetadata: this.currentAdMetadata != null ? this.currentAdMetadata : {},
      errorCode,
      errorMessage,
      timeSinceAdStartedInMs: getMonotonicTimestampInMsSince(this.timestampOfAdStart),
    });
    this.errorSent = true;
  }

  resetSourceRelatedState() {
    this.resetAdBreakData();
    this.adIndex = 0;
  }

  private resetAdBreakData() {
    this.state = SsaiState.IDLE;
    this.isFirstSampleOfAd = false;
    delete this.currentAdMetadata;
    this.resetAdData();
  }

  private resetAdData() {
    delete this.currentAdMetadata?.customData;
    delete this.currentAdMetadata?.adId;
    delete this.currentAdMetadata?.adSystem;
    this.errorSent = false;
    this.currentAdImpressionId = undefined;
    this.sentAdQuartiles = [];
    this.timestampOfAdStart = undefined;
  }
}

function isActivated(eventHandler: SsaiEventHandler | undefined): eventHandler is SsaiEventHandler {
  return eventHandler != null;
}
